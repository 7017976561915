@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,400;1,400&display=swap');

.bg {
  align-items: center;
  background: #F5F6FA;
  display: flex;
  flex-direction: column;
  overflow-anchor: hidden;
  padding: 0;
  position: static;
}

.form-element {
  /* max-height: 817px; */
  background: #ffffff;
  border: 1px solid #bebebe;
  border-radius: 3px;
  margin: 80px 0 0 0;
  max-width: 450px;
  min-height: 500px;
  width: 450px;
}


.form-element-success {
  background: #ffffff;
  border: 1px solid #bebebe;
  border-radius: 3px;
  margin: 80px 0 432px 0;
  max-width: 450px;
  min-height: 369px;
}

.form-element-session-expired {
  background: #ffffff;
  border: 1px solid #bebebe;
  border-radius: 3px;
  margin: 80px 0 216px 0;
  max-width: 450px;
  min-height: 369px;
}

.form-element-error {
  background: #ffffff;
  border: 1px solid #bebebe;
  border-radius: 3px;
  margin: 80px 0 216px 0;
  max-width: 450px;
  min-height: 281px;
}

.form-element-resetmfa-success {
  background: #ffffff;
  border: 1px solid #bebebe;
  border-radius: 3px;
  margin: 80px 0 280px 0;
  max-width: 450px;
  min-height: 353px;
}

.div-container {
  border: 1px solid #bebebe;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  max-height: 793px;
  max-width: 900px;
}

.div-container-activation {
  border: 1px solid #bebebe;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  max-height: 653px;
  max-width: 900px;
}

.form-element-resend-activation {
  background: #ffffff;
  margin: 0;
  max-width: 450px;
}

.form-element-activation {
  background: #ffffff;
  margin: 0;
  max-width: 450px;
}

.child-div {
  float: left;
}

.form-element-username-success {
  background: #ffffff;
  border: 1px solid #bebebe;
  border-radius: 3px;
  margin: 80px 0 216px 0;
  max-width: 450px;

}

.form-element-resend-success {
  background: #ffffff;
  border: 1px solid #bebebe;
  border-radius: 3px;
  margin: 80px 0 464px 0;
  max-width: 450px;
  min-height: 337px;
}

.form-element-short {
  background: #ffffff;
  border: 1px solid #bebebe;
  border-radius: 3px;
  margin: 80px 0 0 0;
  max-width: 450px;
  min-height: 369px;
}

.form-element-unlock-success {
  background: #ffffff;
  border: 1px solid #bebebe;
  border-radius: 3px;
  margin: 80px 0 0 0;
  max-width: 450px;
  min-height: 369px;
}

.form-element-activate-user {
  background: #ffffff;
  border: 1px solid #bebebe;
  border-radius: 3px;
  margin: 40px 0 0 0;
  max-width: 500px;
  width: 500px;
}

.form-element-token-expired {
  background: #ffffff;
  border: 1px solid #bebebe;
  border-radius: 3px;
  margin: 80px 0 44px 0;
  max-width: 500px;
  min-height: 425px;
}

.form-element,
.form-element-success,
.form-element-session-expired,
.form-element-error,
.form-element-resetmfa-success,
.form-element-resend-activation,
.form-element-activation,
.form-element-username-success,
.form-element-resend-success,
.form-element-short,
.form-element-unlock-success,
.form-element-activate-user,
.form-element-token-expired {
  padding: 48px 40px;
}



.InputBase__input {
  background: #ffffff !important;
  border-radius: 3px !important;
  height: 50px !important;
  padding: 8px 16px !important;
}

.InputBase__input:focus {
  border: 1px solid #0a5ec0 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), 0 2px 15px rgba(0, 0, 0, 0.08) !important;
}

.InputBase__input__error {
  border: 2px solid #c60f13 !important;
}

.Label__text__error {
  color: #c60f13 !important;
}

.FeedbackAlert__content__error {
  color: #424242 !important;
}

.activate-user-input-fields {
  margin-bottom: 16px;
  margin-top: 40px;
}

.form-element Input {
  border-radius: 3px;
  /* gap: 16px; */
  height: 50px;
  padding: 8px 16px;
}

.title {
  width: 100%;
}

.Title--left {
  margin-bottom: 0;
}

.feedback-alert {
  margin-top: 40px;
}

.dropdown-style select {
  font-size: 16px;
  padding-right: 45px;
}

.dashed {
  border-top: 1px dashed #bebebe;
  margin-bottom: 20px;
  margin-top: 24px;
}

.form-element p {
  margin-bottom: 0;
}

.description {
  margin-top: 40px;
}

.description-enroll {
  margin-bottom: 40px;
  margin-top: 40px;
}

.headline-style {
  font-size: 16px;
  font-weight: bold;
}

p {
  margin-bottom: 0 !important;
}

.headline-password {
  font-size: 14px;
  font-weight: 700;
}

.desc2 {
  align-self: stretch;
  color: #424242;
  flex: none;
  flex-grow: 0;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  letter-spacing: -0.11px;
  line-height: 24px;
  margin-bottom: 24px !important;
  order: 0;
  width: 100%;
}

.desc2 .help-desk-number {
  display: block;
}

.tab-gap {
  margin-top: 34px;
}

.tab-gap-resend {
  margin-top: 2px;
}

.desc2-enroll {
  font-size: 16px;
}

.out-of-box {
  margin-bottom: 100px;
  margin-top: 44px;
}

.feedback-success {
  margin-bottom: 40px;
  margin-top: 40px;
}

.need-help-token-expired {
  margin-bottom: 308px;
  margin-top: 28px;
}

.need-help-unlock-account-success {
  margin-top: 108px;
  margin-bottom: 164px;
}

.password-input-div {
  display: inline;
  position: relative;
}

.example {
  color: #767676;
  font-size: 12px;
  position: static;
}

.inside-box {
  margin-top: 28px;
}

.box-with-link Button {
  float: right;
  font-size: 12px !important;
}

.InputDropdown {
  margin-bottom: 16px !important;
}

.label-error .Label__text_wrapper span {
  color: #c60f13 !important;
}

.centre-align {
  align-items: center;
  display: flex;
  justify-content: center;
}

.centre-align-lines {
  margin-top: 24px;
}

.centre-align-lines p {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.global-pin-input {
  display: inline-block;
}

.reset-mfa-tooltip {
  margin-left: 0.5rem;
}

.mobile-display {
  display: none;
}

/* forgotusernamesuccess */
.inline-message {
  display: inline;
}

.pin {
  appearance: none;
  border: 1px solid #bebebe;
  border-radius: 0.188rem;
  box-sizing: border-box;
  color: #424242;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-left: 7px;
  margin-right: 5px;
  outline: 0;
  padding: 0.75rem 1rem;
  position: relative;
  width: 13%;
}

.govtId-input {
  margin-top: 34px;
}

/*forgot-username-error-description */
.error-description-text .deposit-account-link {
  margin-top: 12px !important;
}

.error-description-text a {
  text-decoration: none;
  color: #0A5EC0;
}

#governmentIdentifier__input__adv__helper {
  pointer-events: none !important;
  text-decoration: none !important;
  color: #767676 !important;
}

/*fdic*/
.fdic-container{
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--surface-bg-accent3, #084891);
  gap: 10px;
  padding-right: 48px;
  padding-left: 48px;
  padding-top: 8px;
  padding-bottom: 8px;
  position: sticky;
  top: 0;
  z-index: 100;
  margin-top: -0.1px;
}

.fdic-logo{
  width: 37.83px;
  height: 15.88px; 
}

.fdic-logo-text{
  color: #ffffff;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 13px;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

@media (max-width: 570px) {
  .fdic-logo-text{
    max-width: 232px;
  }
}

@media (max-width: 973px) {

  .div-container,
  .div-container-activation {
    border: none;
  }

  .form-element-activation,
  .form-element-resend-activation {
    border: 1px solid #bebebe;
    border-radius: 3px;
  }

}

/* Mobile view style  */
@media (max-width: 570px) {
  .link-style button:focus {
    border: none !important;
    text-decoration: underline !important;
  }

  .out-of-box {
    margin-bottom: 44px;
    margin-top: 28px;
  }

  .feedback-success {
    margin-bottom: 24px;
    margin-top: 32px;
  }

  .button-mobile {
    margin-top: 0 !important;
  }

  .need-help-mobile,
  .need-help-unlock-account-success {
    margin-bottom: 40px !important;
    margin-top: 24px !important;
  }

  .need-help-token-expired {
    margin-bottom: 148px;
  }

  .dropdown-style select {
    font-size: 16px;
  }

  .dropdown-style input {
    font-size: 16px;
  }

  .desc2-enroll {
    font-size: 14px;
  }
}

@media (max-width: 420px) {
  .desc2 .help-desk-number {
    display: inline;
  }
}